
import React from "react";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import ScrollTransparentNavbarEM from "../../components/Navbars/ScrollTransparentNavbarEM";
import htfVideo from '../../assets/img/HTF-2-watermarked.mp4';
// core components

import FooterBlackEM from "components/Footers/FooterBlackEM";


function Cribsy() {
  return (
    <>
    <ScrollTransparentNavbarEM/>
      <div className="cd-section" id="blogs" data-background-color="black">
        <div className="blogs-1" id="blogs-1">
          <Container>

          <div className="separator separator-info"></div>
            <div className="section-story-overview">
              
            <Card className="card-plain card-blog">
                  <Row>
                    <Col md="7">
                      <h6 className="category text-success">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Cribsy
                      </h6>
                      <CardTitle tag="h3">
                        <a href="#phase2-funding">
                        Introducing Cribsy
                        </a>
                        
                      <div className="text-center">
                      
                        
                        <a href="https://apps.apple.com/us/app/cribsy/id6499236782">
                      <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1665100800&h=dcb22a6433c4aa556ff0d7bccb6ba928"
                       alt="Download on the App Store" /></a>
                        

                    <a href='https://play.google.com/store/apps/details?id=com.earlymarkers.cribsy&pcampaignid=web_share'>
                      <img alt='Get it on Google Play' 
                      src={require("assets/img/google_play_badge.png")} height='60px'/></a>
                      </div>
                      </CardTitle>
                      <p className="card-description">
                        We are thrilled to launch Cribsy, the busy, curious parent's AI companion for infant development.
                        Uncover your newborn's incredible development journey right from the comfort of your home. <span className="text-warning">No special 
                        devices needed! Just use your smartphone.</span> <br></br> <br></br>
                        During the early access period, the first 500 parents will get to use Cribsy for free. Use it as long as you
                        like, as often as you like. We will be launching the Cribsy study soon. 
                        <br></br><br>
                        </br>
                        Cribsy has received funding (HD115478) 
                        from the National Institute of Child Health and Human Development (NICHD) of the National Institutes of Health (NIH) 
                        to develop and validate the tool.  <br/>
                        <br/> Stay tuned for more details...

                        <a href="#cribsy_launch" className="text-info">
                          READ MORE
                        </a>
                        
                      </p>
                      <p className="author">
                        {" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                       , July 2024
                      </p>
                    </Col>
                    <Col md="4">
                      <div className="card-image">
                      <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/EMCP5008-For-Cribsy.png")}
                        ></img>
                      </div>
                    </Col>
                  </Row>
            </Card>

             
            <Card className="card-plain card-blog">
                  <Row>
                    <Col md="6">
                          <div>
                            <LiteYouTubeEmbed
                              id="wGbEmuGZ2_I"
                              title="Cribsy: Your AI companion for infant monitoring from home"
                            />
                          </div>
                      
                     
                    </Col>
                    <Col md="6">
                      <h6 className="category text-info">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Intro Video
                      </h6>
                      <CardTitle tag="h3">
                        Watch the Cribsy launch video
                        
                      </CardTitle>
                     
                      <p className="card-description">
                      Most of your baby's development happens at home. Cribsy allows you to get a window into your
                      baby's developmental progress. It will likely be the simplest baby app you will use. All you need
                      is a short video of your baby's movements in the crib. You can upload it from your smartphone, tablet, or 
                      a computer. The hardest part is probably waiting for the AI to do its analysis and deliver a developmental
                      report. We promise a 24-hr turnaround. But you typically get your report in 15 minutes.
                        <br></br> <br></br>
                        During the early access period, the first 500 parents will get to use Cribsy for free. Use it as long as you
                        like, as often as you like.

                        <Button className='btn-primary'
                          href="https://app.cribsy.ai"
                          target="_blank"> Launch Cribsy </Button>
                      </p>
                      <p className="author">
                        {" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                       , June 2024
                      </p>
                    </Col>
                  </Row>
            </Card>

            
            <Card className="card-plain card-blog">
                  <Row>
                    <Col md="6">
                      <h6 className="category text-success">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       How to
                      </h6>
                      <CardTitle tag="h3">
                        How to make a Cribsy video
                        
                      </CardTitle>
                     
                      <p className="card-description">
                      This video contains instructions to parents on how to capture spontaneous activities of the infant in the crib. Once captured, the short video (about 3-minutes duration) 
                      can be uploaded at https://app.cribsy.ai for analysis. Cribsy will then create a personalized developmental report.   
                      <br></br> <br></br>
                      Uncover your newborn's incredible journey of movement with Cribsy. Use your smartphone to capture a 3-minute 
                      video of your 0-4 month old baby on the bed or floor – at any time that is convenient for you and your baby. 
                      Then get ready to monitor, track, 
                      and celebrate your baby's progress! You can use Cribsy as often as you like during your baby’s first four months.
                      </p>
                      <p className="author">
                        {" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                       , Jan 2024
                      </p>
                    </Col>
                    <Col md="6">
                          <div>
                            <LiteYouTubeEmbed
                              id="jKOm49wJ9Aw"
                              title="Cribsy: How to make a crib video"
                            />
                          </div>
                      
                     
                    </Col>
                  </Row>
            </Card>

            
            <Card className="card-plain card-blog">
                  <div id="cribsy_launch" className="card-description">
                  
                  <h2 className="text-warning">
                    Introducing Cribsy
                  </h2>
                  <h5 className="text-info">Your Personal AI Companion for Infant Development Monitoring from the Home</h5>
                  
                  
                  
                  <p className="card-description">
                  Welcoming a newborn into the family is a joyous and exciting time. As parents, we are always eager to ensure the well-being and healthy development of our little ones. 
                  That’s why we, at Early Markers, are thrilled to introduce Cribsy, a revolutionary AI tool designed to monitor your infant’s development right from the comfort of your home.  
                  </p>
                   
                  <p className="card-description">
                   <span className="text-info"><a href="https://app.cribsy.ai">Cribsy </a> </span> is an innovative and user-friendly AI-powered tool that provides parents with valuable insights into their baby's developmental progress. 
                   By leveraging advanced artificial intelligence algorithms, 
                   Cribsy is designed to assist parents in tracking and understanding their infant's growth and development, offering peace of mind and support in this crucial phase of life. 
                   </p>
                   
                   <p className="card-description">
                   Uncover your newborn's incredible journey of movement with Cribsy. Use your smartphone to capture a 3-minute video of your 0-4 month old baby on the bed or floor – 
                   at any time that is convenient for you and your baby. Then get ready to monitor, track, and celebrate your baby's progress! 
                   You can use Cribsy as often as you like during your baby’s first four months.</p>

                   
                   <p className="card-description">
                   <span className="font-italic">“Infant development happens at home, away from the eyes of the pediatrician and other clinicians.” </span> says Early Markers Chief Clinical Officer, 
                   Dr. Teresa Fair-Field, <span className="font-italic">“Our vision, at Early Markers, is to empower parents to easily monitor their infant’s development from home, using just a smartphone.” </span>
                   According to Dr. Fair-Field, a practicing pediatric occupational therapist, 
                   movement is the critical activity to monitor. It is the earliest signal of development you can observe. Motor development influences development in various other domains, 
                   including language, cognition, spatial memory, and behavior.
                   </p>

                   
                   <p className="card-description">
                   <span className="font-italic">“We have built powerful AI models to extract movement insights from infant videos,” </span> says Early Markers founder, Dr. Bharath Modayur.
                   <span className="font-italic"> “Cribsy is the distillation of our work over the past several years, 
                   that brings AI to infant development monitoring, in a package designed for the busy, concerned, and curious parent of a newborn.” </span>
                   </p>

                   
                   <p className="card-description">
                   Cribsy is accessible on any desktop computer, tablet, or smartphone. Just enter your baby’s birth/due dates and upload a short video of your baby in the crib. 
                   Cribsy will process it automatically and bring you a personalized developmental report. 

                   </p>

                   <p className="em-p-light text-success">
                   Sign up and use Cribsy, absolutely free of cost, during our Early Access period. The first 500 users will get to use Cribsy as often as they like.
                   </p>

                   
                   <Button className='btn-primary'
                          href="https://app.cribsy.ai"
                          target="_blank"> Launch Cribsy 
                    </Button>

                   
                  <div className="text-center">
                    <Button
                      className="btn-icon btn-round mr-1"
                      color="info"
                      href="https://www.facebook.com/earlymarkers"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round mr-1"
                      color="info"
                      href="https://twitter.com/earlymarkers"
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                    
                  </div>
                  
                   </div>
                   </Card>
              </div>
          </Container>
        </div>

        
        <FooterBlackEM />
      </div>
    </>
  );
}



export default Cribsy;