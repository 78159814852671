import React from "react";


import {Document, Page, pdfjs} from 'react-pdf';
import RawPrivacy from './rawprivacy.pdf';
import ScrollTransparentNavbarEM from "../../components/Navbars/ScrollTransparentNavbarEM";

import FooterBlackEM from 'components/Footers/FooterBlackEM';

export default function PrivacyComponent() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  
    return (
    <>
    <ScrollTransparentNavbarEM/>

    <Document file={RawPrivacy}>
        <Page pageNumber={1}/>
    </Document>

    <FooterBlackEM/>
    
    </>
    );
}