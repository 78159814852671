/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import PrivacyComponent from "./Privacy";

// core components

function FooterBlackEM() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="/index"
                  target="_blank"
                >
                  Early Markers
                </a>
              </li>
              <li>
                <a
                  href="/landing-page-em#about-em"
                >
                  About Us
                </a>
              </li>

              <li>
                <a
                  href="/privacy"
                >
                  Privacy
                </a>
              </li>
              
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Rights reserved by {" "}
            <a
              href="/index"
            >
              Early Markers
            </a>
            
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlackEM;
