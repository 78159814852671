
import React from "react";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import ScrollTransparentNavbarEM from "../../components/Navbars/ScrollTransparentNavbarEM";
import htfVideo from '../../assets/img/HTF-2-watermarked.mp4';
// core components

import FooterBlackEM from "components/Footers/FooterBlackEM";


function Cribsy_Feedback() {
  return (
    <>
    <ScrollTransparentNavbarEM/>
      <div className="cd-section" id="blogs" data-background-color="black">
        <div className="blogs-1" id="blogs-1">
          <Container>

          <div className="separator separator-info"></div>
            <div className="section-story-overview">
              
            <Card className="card-plain card-blog">
                  <Row>
                    <Col md="7">
                      <h6 className="category text-info">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Cribsy Feedback
                      </h6>
                      <CardTitle tag="h3">
                        <a href="#phase2-funding">
                        Help us improve Cribsy
                        </a>
                        
                      <div className="text-center">
                        <Button
                          className="btn-icon btn-round mr-1"
                          color="info"
                          href="https://www.facebook.com/earlymarkers"
                        >
                          <i className="fab fa-facebook-square"></i>
                        </Button>
                        <Button
                          className="btn-icon btn-round mr-1"
                          color="info"
                          href="https://twitter.com/earlymarkers"
                        >
                          <i className="fab fa-twitter"></i>
                        </Button>
                        
                      </div>
                      </CardTitle>
                      <p className="card-description">
                      We are delighted to introduce you to Cribsy, our innovative developmental screener, crafted with your 
                      child's milestones in mind. Developed by Early Markers, a dedicated research firm backed by the National 
                      Institutes of Health (NIH), our mission is to 
                      empower parents like you with the resources and insights you need to monitor your child's growth and well-being. 
                      <br></br>
                       </p>
                       <p className="card-description">
                  <b>Your Feedback is Gold! </b> As we continue refining and enhancing Cribsy, 
                  your experiences and feedback are invaluable. Your insights not only help us improve, but they contribute to a 
                  community-driven effort aimed at ensuring every child gets the best start in life.
                  Become a part of our transformative journey! Use Cribsy, share your thoughts, and together, let's make a difference in the world of child development.
                   
                  <br></br>
                  <br></br>
                  <p className="text-info">
                    Here's are the steps:
                  </p>
                  <ol>
                    <li>
                      <a href="#cribsy-howto" className="text-warning"> Watch this </a>  to learn how to make a Cribsy video (this is only a minute long).
                    </li>
                    <li>
                      Head over to <a href="https://app.cribsy.ai" target="_blank" className="text-warning">Cribsy</a>, upload crib video, obtain the AI-generated customized 
                      report for you baby, and ask Cribsy Chatbot anything regarding your baby's development.
                      Shouldn't take you more than a few minutes once you have a crib video.
                
                    </li>
                    <li>
                      Fill out the <a href="https://forms.gle/eXt3BPgPkGrV1QiR9" className="text-warning" target="_blank">feedback questionnaire</a>. We will send you a Starbucks card via email as a thank you.
                    </li>
                  </ol>

                        
                      </p>
                      <p className="author">
                        {" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                       , Aug 2023
                      </p>
                    </Col>
                    <Col md="4">
                      <div className="card-image">
                      <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/EMCP5008-For-Cribsy.png").default}
                        ></img>
                      </div>
                    </Col>
                  </Row>
            </Card>

           
            
            <Card className="card-plain card-blog">
              <div id="cribsy-howto">
                  <Row>
                    <Col md="6">
                      <h6 className="category text-success">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       How to
                      </h6>
                      <CardTitle tag="h3">
                        How to make a Cribsy video
                        
                      </CardTitle>
                     
                      <p className="card-description">
                      This video contains instructions to parents on how to capture spontaneous activities of the infant in the crib. Once captured, the short video (about 3-minutes duration) 
                      can be uploaded at https://app.cribsy.ai for analysis. Cribsy will then create a personalized developmental report.   
                      <br></br> <br></br>
                      Uncover your newborn's incredible journey of movement with Cribsy. Use your smartphone to capture a 3-minute 
                      video of your 0-4 month old baby on the bed or floor – at any time that is convenient for you and your baby. 
                      Then get ready to monitor, track, 
                      and celebrate your baby's progress! You can use Cribsy as often as you like during your baby’s first four months.
                      </p>
                      <p className="author">
                        {" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                       , Jan 2023
                      </p>
                    </Col>
                    <Col md="6">
                          <div>
                            <LiteYouTubeEmbed
                              id="jKOm49wJ9Aw"
                              title="Cribsy: How to make a crib video"
                            />
                          </div>
                      
                     
                    </Col>
                  </Row>
              </div>
            </Card>

         
              </div>
          </Container>
        </div>

        
        <FooterBlackEM />
      </div>
    </>
  );
}



export default Cribsy_Feedback;