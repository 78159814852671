import React from "react";

import PosePdf from '../../assets/Pose-Inference-on-Infant-Datasets.pdf';
import AOTA2022Pdf from '../../assets/AOTA-2022-Abstract-Salient-Sets.pdf';
import KomoriPdf from '../../assets/Poster-KOMORI.pdf';
import KernsPdf from '../../assets/Poster-KERNS.pdf';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import ScrollTransparentNavbarEM from "../../components/Navbars/ScrollTransparentNavbarEM";

// core components

import FooterBlackEM from "components/Footers/FooterBlackEM";


function NewsEM() {
  return (
    <>
    <ScrollTransparentNavbarEM/>
      <div className="cd-section" id="blogs" data-background-color="black">
        <div className="blogs-1" id="blogs-1">
          <Container>

            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h3 className="title">Latest News</h3>
                <br></br>
                <p className="em-p-light text-success">
                  The Early Markers blog is moving to this new address -- <a href="https://blog.earlymarkers.com" target="_blank">Blog</a>
                </p>
                <br></br>
                <Card className="card-plain card-blog">
                <Row>


                    <Col md="7">
                    <h6 className="category text-danger">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{"Funding News"}
                      </h6>
                      <CardTitle tag="h3">
                          
                       Cribsy awarded Phase I NIH SBIR grant
                      
                      </CardTitle>
                      <p className="card-description">
                      Cribsy, a new app for parents to track their infant's development, has been awarded a Phase I SBIR grant from the National Institutes of Health (NIH)...  {" "} 
                        <a href="#cribsy-sbir-2024" className="text-info" target="_blank">
                          Read more
                        </a>
                        
                     
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Bharath Modayur</b>
                        </a>
                        , July 2024
                      </p>
                    </Col>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          height={300}
                          src={require("assets/img/EMCP5008-For-Cribsy.png")}
                        ></img>
                      </div>
                    </Col>

                  </Row>
                </Card>

                <Card className="card-plain card-blog">
                <Row>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/elsevier.png")}
                        ></img>
                      </div>
                    </Col>

                    <Col md="7">
                    <h6 className="category text-info">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{"Early Human Development (Elsevier)"}
                      </h6>
                      <CardTitle tag="h3">
                          
                       Enhancing Infant Motor Screening Efficiency
                      
                      </CardTitle>
                      <p className="card-description">
                      Check out our recent article on Elsevier’s journal of Early Human Development... {" "}
                        
                        <a href="https://labs.wsu.edu/emma/2023/02/27/enhancing-infant-motor-screening-efficiency/" className="text-info" target="_blank">
                          Read more
                        </a>
                        
                     
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , March 2023
                      </p>
                    </Col>



                  </Row>
                </Card>

                <Card className="card-plain card-blog">
                <Row>


                    <Col md="7">
                    <h6 className="category text-warning">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{"Student Research"}
                      </h6>
                      <CardTitle tag="h3">
                          
                       Doctoral students at Early Markers
                      
                      </CardTitle>
                      <p className="card-description">
                      Virtual student research opportunities are continuing here at Early Markers! 
                      We are hosting three new occupational therapy students- Kiana A., Sheri K., and Ginny K. from Pacific University... {" "}
                        
                        <a href="#capstone-2022" className="text-info" target="_blank">
                          Read more
                        </a>
                        
                     
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , March 2022
                      </p>
                    </Col>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/capstone-students-at-work.png")}
                        ></img>
                      </div>
                    </Col>

                  </Row>
                </Card>


                <Card className="card-plain card-blog">
                <Row>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/KinderingPromoV2.jpeg")}
                        ></img>
                      </div>
                    </Col>

                    <Col md="7">
                    <h6 className="category text-danger">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{"Kindering Benefit"}
                      </h6>
                      <CardTitle tag="h3">
                          
                       Salute to Courage Benefit
                      
                      </CardTitle>
                      <p className="card-description">
                      Early Markers was proud to join the Salute to Courage matching pool to benefit Kindering clinic's mission of supporting families early.
                      Watch the {" "}
                        
                        <a href="https://www.youtube.com/watch?v=TXuopKyauwA&t=1s" className="text-info" target="_blank">
                          promo video
                        </a>
                        
                     
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , Oct 2021
                      </p>
                    </Col>
                  </Row>
                  </Card>


                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="7">
                    <h6 className="category text-info">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Salient Motor Items
                      </h6>
                      <CardTitle tag="h3">
                        <a href="http://labs.wsu.edu/emma/2020/09/14/summer-research-opportunity-at-early-markers/">
                          Can a few salient motor items predict infant motor score?
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      We looked for an abridged set of salient motor items that can predict an infant's motor score. This is the first, critical step toward automating infant developmental (motor) 
                      assessment from home videos…{" "}
                        <a href="#aota2022" className="text-info" target="_blank">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , Aug 2021
                      </p>
                    </Col>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/pilot-zoom-session.jpg")}
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog">
                <Row>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/ai-injection-card.png")}
                        ></img>
                      </div>
                    </Col>

                    <Col md="7">
                    <h6 className="category text-warning">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{"Your AI Injection"}
                      </h6>
                      <CardTitle tag="h3">
                          
                       Using AI to screen for infant motor deficits
                      
                      </CardTitle>
                      <p className="card-description">
                      Early Markers founder, Bharath Modayur, chats with AI expert, Deep Dhillon, on developmental screening of infants using Artificial Intelligence tools...{" "}
                        
                        <a href="https://podcast.xyonix.com/1785161/8978015-using-ai-to-detect-motor-delays-in-infants-interview-with-bharath-modayur" className="text-info" target="_blank">
                          Listen
                        </a>
                        
                     
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , July 2021
                      </p>
                    </Col>
                  </Row>
                  </Card>

                <Card className="card-plain card-blog">
                <Row>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/pilot-personnel.jpg")}
                        ></img>
                      </div>
                    </Col>

                    <Col md="7">
                    <h6 className="category text-warning">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{"Early Developmental Screening"}
                      </h6>
                      <CardTitle tag="h3">
                        <a href="https://labs.wsu.edu/emma/2021/06/13/early-screening-pilot-at-kindering-center/">
                          
                       Early Developmental Screening: Pilot at Kindering Center
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      We launched a pilot program for early developmental (motor) screening (NICHD SBIR Grant HD095783)
                      at the Kindering Center, which is the largest neurodevelopmental birth-to-three center in WA state...{" "}
                        
                        <a href="https://labs.wsu.edu/emma/2021/06/13/early-screening-pilot-at-kindering-center/" className="text-info" target="_blank">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , May 2021
                      </p>
                    </Col>
                  </Row>
                  </Card>

                <Card className="card-plain card-blog">
                <Row>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/anna_babyem_small.jpeg")}
                        ></img>
                      </div>
                    </Col>

                    <Col md="7">
                    <h6 className="category text-info">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{"Parent Testimonial"}
                      </h6>
                      <CardTitle tag="h3">
                        <a href="http://labs.wsu.edu/emma/2020/09/15/emma-study-parent-testimonial/">
                          
                       EMMA Study: Parent Testimonial
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      Parent, Anna, whose 3-month-old, Baby Em, is an EMMA study participant, was kind enough to share her experience with us…{" "}
                        <a href="http://labs.wsu.edu/emma/2020/09/15/emma-study-parent-testimonial/" className="text-info" target="_blank">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , Sep 2020
                      </p>
                    </Col>
                  </Row>
                  </Card>

                  <Card className="card-plain card-blog">
                  <Row>
                    <Col md="7">
                    <h6 className="category text-info">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Summer Research Opportunity
                      </h6>
                      <CardTitle tag="h3">
                        <a href="http://labs.wsu.edu/emma/2020/09/14/summer-research-opportunity-at-early-markers/">
                          Summer Research Internship at Early Markers
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      At Early Markers, we hosted three Washington State University (WSU) medical students in a summer
                      internship program…{" "}
                        <a href="http://labs.wsu.edu/emma/2020/09/14/summer-research-opportunity-at-early-markers/" className="text-info" target="_blank">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , Sep 2020
                      </p>
                    </Col>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/interns_on_zoom.jpg")}
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/zoom_covid_19.png")}
                        ></img>
                      </div>
                    </Col>
                    <Col md="7">
                    <h6 className="category text-warning">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Covid Modification
                      </h6>
                      <CardTitle tag="h3">
                        <a href="#covid-mod">
                          Early Markers infant studies are going virtual
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      Due to the restrictions imposed by the Covid-19 pandemic, we decided to adapt.
                      Our infant studies are now virtual…{" "}
                        <a href="#covid-mod" className="text-info">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Mara Modayur</b>
                        </a>
                        , May 2020
                      </p>
                    </Col>
                  </Row>
                </Card>


                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="7">
                    <h6 className="category text-danger">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Infant Pose Estimation
                      </h6>
                      <CardTitle tag="h3">
                        <a href="#pose-estimation">
                          Infant Pose Estimation toward Facilitating Automated Screening
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      We have now built a state-of-the-art pose estimator that detects infant
                      body joints from video frames…{" "}
                        <a href="#pose-estimation" className="text-info">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                        , Feb 2020
                      </p>
                    </Col>

                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/pose-article-thumbnail.png")}
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/infant_photo_shoot.png")}
                        ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <h6 className="category text-info mt-3">Infant Modeling Shoot</h6>
                      <CardTitle tag="h3">
                        <a href="#ot-shoot">
                          Creating content for OT-based activities for parents
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      Early Markers' pediatric motor consultant, Dr. Teresa Fair-Field, led a motor movement video 
                      session in the Early Markers study room on a recent Saturday.…{" "}
                        <a href="#ot-shoot" className="text-info">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Teresa Fair-Field</b>
                        </a>
                        , Fall 2019
                      </p>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="7">
                      <h6 className="category text-danger">
                        <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                       Funding
                      </h6>
                      <CardTitle tag="h3">
                        <a href="#phase2-funding">
                        Early Developmental Risk Screener
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        Sep 2018. We are thrilled to receive an SBIR Phase II (Small Business Innovation Research) grant from the 
                        Eunice Kennedy Shriver National Institute of Child Health & Human Development (NICHD) of the National 
                        Institutes of Health (NIH)...

                        <a href="#phase2-funding" className="text-info">
                          Read More
                        </a>
                      </p>
                      <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <b>Early Markers</b>
                        </a>
                       , Fall 2018
                      </p>
                    </Col>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/studyroom-wall.jpg")}
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>


        <div id="cribsy-sbir-2024" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Phase I funding for Cribsy from the National Institute of Child Health and Human Development (NICHD)
                  </h3>
                  <i className="text-primary"> July 2024</i>
                  <p>
                  We're thrilled to announce that our early developmental screener for infants, Cribsy, has been awarded a prestigious Phase I SBIR (Small Business Innovation Research) grant from the Eunice Kennedy Shriver National Institute of Child Health & Human Development (NICHD) 🎉
                  <br></br> <br></br>
In underserved and rural communities, the need for early developmental screening and early intervention is pressing. Our goal is to develop Cribsy into a scalable AI system that can be widely adopted, ensuring that minority populations are adequately represented and racial/cultural biases in AI systems are addressed.
This Phase I funding will allow us to refine Cribsy, making it more efficient, stable, scalable, and cost-effective. We are excited to take this significant step towards improving early developmental screenings and making a positive impact on infant health.
Stay tuned for more updates as we advance this groundbreaking project! 🚀 <br></br> <br></br>
Cribsy is an innovative smartphone-based telehealth screener designed to identify neuromotor risks in infants. 
This user-friendly, automated tool aims to address a critical need: early diagnosis and intervention for developmental disabilities, which affects 5-10% of children in the US.
<br></br> <br></br>
NIH SBIR is the largest source  of early stage (non-dilutive) capital for the life sciences (www.sbir.gov)
<br></br> <br></br>
#NIHGrant #Telehealth #Innovation #AI #EarlyIntervention #InfantHealth #MachineLearning #Cribsy #SBIR

               
                 
                  </p>
                  
                </Col>
              </Row>
            </Container>
          </div>

        <div id="capstone-2022" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Student research opportunity at Early Markers
                  </h3>
                  <i className="text-primary"> March 2022</i>
                  <p>
                  Virtual student research opportunities are continuing here at Early Markers! 
                  We are hosting three new occupational therapy students- Kiana A., Sheri K., and Ginny K. from 
                  <b>
                  <a href="https://www.pacificu.edu/academics/colleges/college-health-professions/school-occupational-therapy"
                  target="_blank"> Pacific University</a></b>. 
                  The doctoral students are completing their capstones with us via Zoom and Slack to assist with our 
                  current infant studies.
<br/>
<br/>
Sheri’s capstone project focused on studying the 
<b> efficiency in administration and inter-rater reliability of the Alberta Infant Motor Scale [AIMS]</b> salient-set screening tool. This research will support Early Makers in the development of an efficient and reliable automated screening tool for the detection of motor delay in the first year infant.
<br/>
<br/>
Kiana’s capstone focused on collecting pre-pilot <b>customer discovery insights</b> from a questionnaire and two focus 
groups. The goal is to <b>understand the experience of preterm infant parents</b>; specifically their concerns, access to resources, experience with current health care services, and response to the EMMA app concept (now available on Google Play and the Apple app store).
<br/>
<br/>
The outcome of Ginny’s capstone is the study and creation of <b>content for the EMMA app’s library of Parent Activity 
  Modules (PAM)</b>. The PAM library will provide educational resources to parents in an accessible format to promote developmental milestones in infants using a parent-led, play-based intervention approach. 
<br/>
All three graduate students have found this to be a great opportunity to learn about infant development, clinical research, the administration of infant motor development assessments, and the creation of family-centered media content. Watch for their OTD capstones to be presented in poster sessions at the American Occupational Therapy Association [AOTA] conference, Inspire 2023 in Kansas City, MO.

<br/>
The experience has overall been wonderful for both our Early Markers team and students alike. We are excited to see how their research questions of interest take shape and help us in creating content for our EMMA app!

                 
                   <br>
                  </br>
                  <br></br>
                 
                  </p>
                  
                </Col>
              </Row>
            </Container>
          </div>

        <div id="aota2022" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Salient Motor Items to Predict Infant Motor Development
                  </h3>
                  <i className="text-primary"> Aug 2021</i>
                  <p>

                  Alberta Infant Motor Scale (AIMS) is a standardized clinical tool for assessing infant motor development from observation sessions. A developmental expert looks for the presence
                  (absence) of 58 different motor items expressed by the infant during these sessions. But are all 58 items necessary? Can you predict the infant motor score accurately using only 
                  a subset of these 58-item set? If only a few, say 10, items are significant, you can automate detection of those 10 items from home videos of infant activities. This can lead to
                  more efficient screening of infants for motor developmental delays without human intervention. Read more in the article below.
                  <br>
                  </br>
                  <br></br>
                  <b>
                   <a href = {AOTA2022Pdf} target = "_blank"> Check out the  article here </a>
                   </b>
                  </p>
                  
                </Col>
              </Row>
            </Container>
          </div>

          <div id="aota2023" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Development of a valid and reliable salient set of the Alberta Infant Motor Scale (AIMS)
                  </h3>
                  <i className="text-primary"> Sep 2022</i>
                  <p>

                  Alberta Infant Motor Scale (AIMS) is a standardized clinical tool for assessing infant motor development from observation sessions. A developmental expert looks for the presence
                  (absence) of 58 different motor items expressed by the infant during these sessions. But are all 58 items necessary? Can you predict the infant motor score accurately using only 
                  a subset of these 58-item set? If only a few, say 10, items are significant, you can automate detection of those 10 items from home videos of infant activities. This can lead to
                  more efficient screening of infants for motor developmental delays without human intervention. 

                  <br>
                  </br>

                  Continuing our <a href='https://www.sciencedirect.com/science/article/pii/S0378378223000191?dgcid=author'>work on abridging AIMS, </a> we calculated the criterion
                  validity, interrater reliability and administration time savings of our salient set instrument. We observed very high correlation between groundtruth and the predicted
                  scores using our salient set. Evaluation (scoring) of the salient set took considerably less time than scoring the full AIMS.
                  <br></br>
                  <b>
                   <a href = {KomoriPdf} target = "_blank"> Check out the  article here </a>
                   </b>
                  </p>
                  
                </Col>
              </Row>
            </Container>
          </div>


          <div id="aota2023a" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Development of app-based parent-led modules to improve parental confidence
                    and infant development
                  </h3>
                  <i className="text-primary"> Oct 2022</i>
                  <p>

                 We are developing short videos that can be help parents deliver intentional,
                 goal-directed play that enriches infant development.
                  <br>
                  </br>
                This library of Parent Activity Modules (PAM) provides intervention tools following
                early detection of developmental delays.
                 
                  <br></br>
                  <b>
                   <a href = {KernsPdf} target = "_blank"> Check out the  article here </a>
                   </b>
                  </p>
                  
                </Col>
              </Row>
            </Container>
          </div>


        <div id="covid-mod" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Infant studies going virtual
                  </h3>
                  <i className="text-primary"> May 2020</i>
                  <p>
                  The recent pandemic has now made it impossible for us to do 'live' data gathering and infant developmental
                  assessment sessions. So, we are adapting. Our data gathering and assessment protocols have been modified
                  to accommodate virtual assessments and data gathering via secure Zoom. Instead of Early Markers study team
                  conducting the assessment, the team will now guide the parent/caregiver to interact with the infant while on Zoom.
                  <br>
                  </br>
                  <br></br>
                  Early Markers studies, with these Covid modifications, are overseen by Institutional Review Boards (IRB) at 
                  Ethical and Independent Review Services (Study 16072) and Washington State University (Study 17893) . 
                  </p>
                  
                </Col>
              </Row>
            </Container>
          </div>


        <div id="pose-estimation" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Infant Pose Estimator
                  </h3>
                  <i className="text-primary"> Feb 2020</i>
                  <p>
                  It was a laborious process, building a pose estimator that can automatically determine 'where'
                  and infant subject is from video observation. Once the body joints (pose) are located, we can then
                  determine the specific motor activity. All steps toward automating early developmental screening of
                  infants with developmental and behavioral conditions.
                  Through the MIMM study, we collected high-quality infant movement data from over 60 infants. Over a 
                  million body joints were manually annotated. Using this data, we have trained a Deep Learning system to
                  recognize body parts from video data. We then compared it to a state-of-the-art pose estimation (OpenPose).
                  <br>
                  </br>
                  <br></br>
                  <b>
                   <a href = {PosePdf} target = "_blank"> Check out the  article here </a>
                   </b>
                  </p>
                  
                </Col>
              </Row>
            </Container>
          </div>


        <div id="ot-shoot" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Infant photo shoot to create OT content for parents
                  </h3>
                  <i className="text-primary"> Fall 2019</i>
                  <p>
                  Early Markers' pediatric motor consultant, Dr. Teresa Fair-Field, led a motor movement video session 
                  in the Early Markers study room on a recent Saturday. Dr. Fair-Field's background in motor development, paired with eight infants, 
                  provided valuable video footage of expected movement in the first 3-6 months of life.    
                  Early Markers will use the captured videos to build Occupation Therapy (OT)-based interactive parent-led activity clips, 
                  providing parents with developmentally supportive movement activities, in the context of everyday play. These activities can augment the 
                  motor development of their infant, either with or without developmental delays.  

                  </p>
                  <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/Screenshot_Infant_Shoot.png")}
                        ></img>
                      </div>
                </Col>
              </Row>
            </Container>
          </div>

        <div id="phase2-funding" className="section" data-background-color="black">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    Early Developmental Risk Screener -- Phase II is getting off the ground
                  </h3>
                  <i className="text-primary"> Fall 2018</i>
                  <p>
                  We are thrilled to receive an SBIR Phase II (Small Business Innovation Research) grant from the Eunice Kennedy 
                  Shriver National Institute of Child Health & Human Development (NICHD) of the National Institutes of Health (NIH).
                  This funding will be used to develop and commercialize an automated developmental risk assessment tool that is 
                  clinically deployable, smartphone-based and highly cost effective for widespread deployment to better identify 
                  children who need further clinical follow up. Early detection and therapeutic intervention of at-risk children 
                  for developmental delay has enormous economic and societal benefits but the current screening regime fails to 
                  identify many of these at-risk children as infants or early toddlers.
                  </p>
                  <br/>

                  <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/studyroom1.jpg")}
                        ></img>
                      </div>
                  <p>
                  <br></br>
                  <b>Project Title:</b> An Automated Early Motor Development Risk Screener from Observational Video Recordings of 
                  Infants and Toddlers
                  </p>
                  <p>
                  <b>Principal Investigator:</b> Bharath Modayur, Ph.D.
                  </p>
                  <p>
                  <b>Grant:</b> 9R44HD095783-03
                  </p>
                  <p>
                  <b>Funding Institute:</b> Eunice Kennedy Shriver National Institute of Child Health & Human Development
                  </p>
                  <p>
                  <b>Funding Mechanism:</b> Small Business Innovation Research (SBIR) program -- the largest funder of early stage 
                  startups in the country. Learn more about
                  them <a href="https://www.sbir.gov/about/about-sbir" target="_blank"> <b> <u>here</u> </b></a> 
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

        <FooterBlackEM />
      </div>
    </>
  );
}

export default NewsEM;
