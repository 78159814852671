import React from "react";

import MIMMFlyerPdf from '../../assets/Study-Flyer-with-QR-Code -2020-Final.pdf';
import MIMMInstructions3Months from '../../assets/MIMMRemote-ParentGuidance-3months-pages-1.pdf';
import MIMMInstructions6Months from '../../assets/MIMMRemote-ParentGuidance-6months-pages-1.pdf';
import EMMACPConsentForm from '../../assets/EMMA CP Permission Form V1 Oct 2022 STAMPED.pdf';

import htfVideo from '../../assets/img/HTF-2-watermarked.mp4';
import btbVideo from '../../assets/img/BTB1-30s.mov';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Collapse,
  FormGroup,
  Form,
  Input,
  Modal,
  ModalFooter,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  UncontrolledCollapse
} from "reactstrap";


import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box, Grid}
from '@mui/material';

import { ExpandMoreOutlined } from "@mui/icons-material";
import {Typography} from '@mui/material';

import ScrollTransparentNavbarEM from "../../components/Navbars/ScrollTransparentNavbarEM";

import ScrollTransparentNavbar from "../../components/Navbars/ScrollTransparentNavbar";
import FooterBlackEM from "components/Footers/FooterBlackEM";
import { isElementAccessExpression } from "typescript";



function StudiesEM(props) {

  const [propState, setPropState] = React.useState(props);

  // header 3 carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(propState.projectindex);
  const [animating, setAnimating] = React.useState(false);

  const [emmaModal, setEmmaModal] = React.useState(false);
  const [mimmModal, setMimmModal] = React.useState(false);
  const [qualifyModal, setQualifyModal] = React.useState(false);
  const [appsModal, setAppsModal] = React.useState(false);


  const [videoModal, setVideoModal] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [appsExpanded, setAppsExpanded] = React.useState(false);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel: false);
  }


  const handleAppsChange = (panel) => (event, isExpanded) => {
    setAppsExpanded(isExpanded ? panel: false);
  }
  

  const onExiting = () => {
    setAnimating(false);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  //CAROUSEL ITEMS

// core components
const items = [

  {
    src: "url(" + require("assets/img/emma-project-background.jpg") + ")",
    content: (
      <div>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2 className="title">Project Cribsy</h2>
          
          <p className="text-info">

          <b>This study will be launched in Summer/Fall 2024</b>
          </p>
          <p className="em-p-light"> 
          We are exploring spontaneous infant movements in the first year of development and how they are linked to neuromotor risks including Cerebral Palsy. 
          The goal is to develop a home-based mobile app to detect early neurodevelopmental risk indicators. 
          </p>

          <br></br>
          
          <p className="em-p-light">
          We will be recruiting infants under 12 months of age. 
          You will need a smartphone to acquire 3-minute videos of baby in the crib (or floor). 
          The study will support both English and Spanish speaking parents.
          </p>
          <div className="buttons">


              <Button
                disabled
                className="btn-neutral mr-1"
                color="success"
                href="https://forms.office.com/r/sf3nWsy1mE"
                target="_blank"
                // onClick={()=>setEmmaModal(true)}
                size="sm"
              >
                <i className="now-ui-icons files_single-copy-04"></i> <b>Enroll </b>
              </Button>

              <Button
                disabled
                className="btn-neutral mr-1"
                color="info"
                href="#"
                onClick={()=>setQualifyModal(true)}
                size="sm"
              >

                <i className="now-ui-icons ui-1_check"></i> <b>What's involved </b>
              </Button>
           
              <Button
                  className="btn-neutral mr-1"
                  color="danger"
                  href="#"
                  size="sm"
                  onClick={()=>setAppsModal(true)}
                >
                <i className="now-ui-icons tech_mobile"></i> <b>Apps</b>
              </Button>
            
           
            <p className="em-p-light">
              <br></br>
            This research study has been approved by Salus IRB #22033.
              The Early Markers study group or Principal Investigator, Dr. Modayur can be contacted at 
              <br></br>
              <a href="mailto:study@earlymarkers.com"> Email </a> 
              <br></br>
              <a href="tel:12067452872">Phone at 1-206-745-2872</a>
              <br/>
            </p>
            <Modal 
                fullScreen
                isOpen={emmaModal}
                toggle={() => setEmmaModal(false)}>
              <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setEmmaModal(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="category text-info" align="center">Infant Motor Movement Analysis Research Study (EMMA)</h4>
                </div>
                <div className="modal-body">
                 
              <h6>CALL FOR PARTICIPATION</h6>
             
              <p className="em-p-dark">
              A small Seattle research firm, Early Markers (www.earlymarkers.com), funded by the National Institutes of Health (NIH), 
              is recruiting infants for a study to improve understanding of infant motor movements, especially in preterm infants.   
              We intend to develop an early home-based risk screener for motor disorders, including Cerebral Palsy. 
              </p>
              <br></br>

              <p className="em-p-dark">
                We are looking for:
                <ul>
                  <li>
                  Infants between one and five months old <b>and</b>
                  </li>
                  <li>
                  who were premature (less than 37 weeks gestation).
                 
                  
                  </li>
                </ul>
              
              <br></br>
             
              The parent will use their smartphone, video the infant – awake and not fussy – in the crib, at home, for 3 minutes and upload the short 
              video to a secure portal. A licensed occupational or physical therapist will review the infant’s movements and produce a risk assessment 
              for neuromotor risks, including Cerebral Palsy. If appropriate, the parent will also receive referrals for clinical follow-up and care.
              <br></br>
              <br></br>
              We expect that the data derived from your child will allow us to build a system that can offer early, 
              in-home evaluation of infant development and assess risks for conditions such as Cerebral Palsy.
             
              <br></br>
              <br></br>
              We thank you for considering participation in this important research topic.
              <br></br>
              This research study has been approved by E&I IRB #22033.
 
              <br></br>
              Email us at study@earlymarkers.com - Or call/text 206 745 2872
              <br></br><br></br>
              The Early Markers study group or Principal Investigator, Dr. Bharath Modayur can be contacted at 
              <br></br>
              <a href="mailto:study@earlymarkers.com"> Email </a> 
              <br></br>
              <a href="tel:12067452872">Phone at 1-206-745-2872</a>
              <br/>

              </p>

              </div>
                <ModalFooter>
               
                  <Button color="primary" onClick={() => setEmmaModal(false)}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal 
                fullScreen
                isOpen={qualifyModal}
                toggle={() => setQualifyModal(false)}>
              <div className="modal-header justify-content-center">
                <Typography variant='body1'>
                <Box sx={{ fontWeight: 'bold'}}>Infant Movement Study: Call for Participation
                </Box> </Typography>
              </div>
              <div className="modal-body">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color='primary'>Who we're looking for</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Card><CardBody>
                      <ul>
                        <li> Infants between one and five months old</li>
                        <li> and premature (less than 37 weeks gestation)</li>
                      </ul>
 
                     
                    </CardBody></Card>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                     <Typography color='primary'>Step 1: If you are interested</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                  <Card><CardBody>
                    If you are interested in participating and would like to talk to a member of the study team, 
                    make a Calendly appointment (15-minute phone or zoom call) 
                    { } <b><a href='https://calendly.com/emma-cp-study'>here</a></b>.

                    The study team would be happy to explain the process to you and answer questions about what is involved.
                    <br/><br/>
                    If you think the process is straightforward after reading the 
                    <b color="blue"><a href={EMMACPConsentForm} target="_blank"> Consent Form</a></b>, 
                    and you don’t need any further clarification, you can skip the initial phone/zoom call step and go to Step 2.
.
                    </CardBody></Card>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color='primary'>Step 2: Signing the consent form</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                  <Card><CardBody>
                      You will sign the Consent Form online. The study team will explain the consent form as needed.
                      <br/>
                      The study team will send you an email/text to coordinate this process.
                      
                    </CardBody></Card>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>

                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                     <Typography color='primary'>Step 3: Acquire video from crib</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                  <Card><CardBody>
                    When directed by the study team, acquire a 3 minute video of your infant. 
                    Upload the video via the Early Markers app. In total, you will upload six such videos, one per week.
                    <br/>
                    The parent will use their smartphone, video the infant – awake and not fussy – in the crib, at home, for 3-5 minutes and upload the short video to a secure portal. 
                    <br/>
                    A licensed occupational or physical therapist will review the videos and produce a risk assessment 
                    report to be shared with you within a week of the last video upload. 

                    <iframe width="300" src="https://www.youtube.com/embed/C7xtMcyHmQ4" title="EMMA: How to make crib videos" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </CardBody></Card>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                     <Typography color='primary'>Step 4: Receive risk assessment report</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                  <Card><CardBody>
                    A trained physical/occupational therapist will review the videos and generate a risk assessment 
                    report to be shared with you within a week of the final video upload.
                    <br/>
                    
                    As a thank you, you will also receive a $100 gift deposited via Venmo or Paypal.
                    <br/>
                    Your participation in the study is now over. Thank you.

                    </CardBody></Card>
                  </AccordionDetails>

                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                     <Typography color='orange'>Learn more</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                  <Card><CardBody>
                    
                  <Typography color='lightblue'>
                    
                  <Box sx={{ fontWeight: 'bold'}}>What is Cerebral Palsy?</Box></Typography>
                  
Cerebral Palsy is a motor disorder that affects body movement, muscle control/coordination/tone, and balance. 
It can also impact fine and gross motor functions. Cerebral Palsy is mostly caused by brain damage – before birth, during delivery, or immediately after. Cerebral Palsy affects about 4/1000 children born in the US.
Preterm infants are at high risk for this condition. The more preterm and lower the birth weight, the higher the risks. 
Almost 10% of infants in the US are born preterm.

Check out www.cerebralpalsy.org.au for more information
                  <br/><br/>
                  <Typography color='lightblue'>
                  <Box sx={{ fontWeight: 'bold'}}>Early Intervention</Box></Typography>
                  
Here is what the <b><a href='https://cerebralpalsy.org.au' target="_blank">Cerebral Palsy Alliance</a></b>  says about early 
identification and early intervention:
<Box sx={{ fontWeight:'bold'}}>
Cerebral palsy can now be accurately diagnosed as early as three months of age
</Box> (emphasis added). 
With a diagnosis early intervention can begin. Early intervention refers to specialized supports and services that are introduced early in life to help a child develop and reach their full potential. Early intervention can help with movement, cognition, communication, feeding, nutrition, and growth, sleeping and family wellbeing. Research shows that starting intervention as early as possible will give any child, regardless of their condition or diagnosis, their best chance of learning.”
This <b> <a href='https://www.cerebralpalsyguidance.com/2021/04/26/infants-benefit-from-early-interventions-for-cerebral-palsy/' target="_blank">article</a> </b>
reviews the benefits of early intervention for Cerebral Palsy.
Here is the journal article that includes a systematic review of early intervention methods and efficacies.

                    <Typography>
                    <Box sx={{ fontStyle: 'italic', m: 1 }}>
                    Damiano DL. Early intervention evidence for infants with or at risk for cerebral palsy: an overview of systematic reviews. Developmental Medicine and Child Neurology. Vol 63 (7), pp-771-784. July 2021.
                    </Box>
                    </Typography>
                    </CardBody></Card>
                  </AccordionDetails>

                </Accordion>
              </div>

              <ModalFooter>
               
               <Button color="primary" onClick={() => setQualifyModal(false)}>
                 Close
               </Button>
             </ModalFooter>
              </Modal>


              <Modal 
                fullScreen
                isOpen={appsModal}
                toggle={() => setAppsModal(false)}>
              <div className="modal-header justify-content-center">
                <Typography variant='body1'>
                <Box sx={{ fontWeight: 'bold'}}>Cribsy App
                </Box> </Typography>
              </div>
              <div className="modal-body">
              <Accordion expanded={appsExpanded === 'appsPanel1'} onChange={handleAppsChange('appsPanel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color='primary'>iOS App</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <a href="https://apps.apple.com/us/app/cribsy/id6499236782">
                      <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1665100800&h=dcb22a6433c4aa556ff0d7bccb6ba928"
                       alt="Download on the App Store" /></a>

                  </AccordionDetails>
                </Accordion>

              <Accordion expanded={appsExpanded === 'appsPanel2'} onChange={handleAppsChange('appsPanel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color='primary'>Android App</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  
                    <a href='https://play.google.com/store/apps/details?id=com.earlymarkers.cribsy&pcampaignid=web_share'>
                      <img alt='Get it on Google Play' 
                      src={require("assets/img/google_play_badge.png")} height='83px'/></a>

                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={appsExpanded === 'appsPanel3'} onChange={handleAppsChange('appsPanel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color='primary'>How to get started</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      

                    <Typography color='black'>
                      Here are some tips on how best to take crib videos.
                    </Typography>

                    <a href="https://www.youtube.com/watch?v=C7xtMcyHmQ4" target="_blank">
                      Watch it on YouTube (English)</a>
                    <br/>
                    <br/>
                    <a href="https://youtu.be/81fX677PoHM" target="_blank">
                      Watch it on YouTube (Spanish)
                      </a>

                  </AccordionDetails>
                </Accordion>

                <ModalFooter>
               
               <Button color="primary" onClick={() => setAppsModal(false)}>
                 Close
               </Button>
             </ModalFooter>
              </div>
              </Modal>

          </div>
        </Col>
      </Row>
      
        

      </div>
    ),
    altText: "",
    caption: ""
  },
  {
    src: "url(" + require("assets/img/studyroom-wall-dark.jpg") + ")",
    content: (
      <div>
      <Row>
       
        <Col className="ml-auto mr-auto text-center" md="8">
        <h2 className="title">Motor Minutes</h2>
          <p className="em-p-light"> 
            We are building a library of Parent Activity Module (PAM) videos to help parents with play-based activities that can augment their child's development and
            enhance parent-child bond.
            <br></br>
            The play-based activity videos will be delivered as part of the EMMA app that is currently under development. Look here for some sample videos.
     
          </p>

         <div className="buttons">
            <Button
              className="btn-neutral mr-1"
              color="red"
              href="#"
              onClick = {()=>setVideoModal(true)}
              size="lg"
              id="sampleVideoToggler"
            >
              <i  className="now-ui-icons files_single-copy-04"></i>  Click here
            </Button>

            <Button
              color="info"
              href="/index#contact-em"
              size="lg"
            >
              <i className="now-ui-icons arrows-1_share-66"></i> Contact Us
            </Button>
           
          </div>

          <Modal 
                isOpen={videoModal}
                toggle={() => setVideoModal(false)}>
              <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setVideoModal(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="category text-info">Sample Motor Minutes</h4>
                </div>
                <div className="modal-body">
                <Card className="card-blog card-plain" data-background-color="black" style={{ item: 'center' }}>

                  <CardBody>
                      <h6 className="category text-info">Hands to Feet</h6>
                      <CardTitle tag="h4">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                          Bringing hands to feet
                          </a>
                      </CardTitle>
                      <p className="card-description">
                        Wake up baby's feet and legs with touch and texture.
                      </p>
                    
                    
                      <div className="card-video">
                              <video controls
                                alt="..."
                                width="420"
                              >
                                <source src={htfVideo} type="video/mp4"/>
                              </video>
                          </div>
                    </CardBody>

                  <CardBody>
                      <h6 className="category text-warning">Back To Belly</h6>
                      <CardTitle tag="h4">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                         Back to belly
                          </a>
                      </CardTitle>
                      <p className="card-description">
                        Shows how to begin with gentle rocking back and forth from the hips; the weight shift that begins rolling.
                      </p>
                    
                    
                      <div className="card-video">
                              <video controls
                                alt="..."
                                width="420"
                              >
                                <source src={btbVideo} type="video/mp4"/>
                              </video>
                          </div>
                    </CardBody>

                  </Card>
                </div>
                <ModalFooter>
                 
                  <Button color="primary" onClick={() => setVideoModal(false)}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>

        
        </Col>
      </Row>
     
      <Row>

        <Col className="ml-auto mr-auto" md="8">

        
        </Col>
      </Row>
      </div>
    ),
    altText: "",
    caption: ""
  },
  {
    src: "url(" + require("assets/img/studyroom-adele.jpg") + ")",
    content: (
      <div>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2 className="title">Project MIMM</h2>
          <p className="em-p-light"> 
           {/* used to be  <h4 className="description text-white"> */}
           
          <p className="text-success">
            
            <b> Enrollment for this study is now closed. We successfully completed the pilot thanks to the  wonderful, skilled Kindering
              staff and the generous parents. Watch this space for further updates.
            </b>
            
          </p>
          <br></br>
             We are now kicking off the final portion of this infant study at Kindering, our clinical partner. Kindering is WA state's largest birth-to-three
            neurodevelopmental center. We are excited to partner with Kindering in bringing an early developmental screening tool to underserved population.
          </p>
          <br></br>
          <div className="buttons">
            <Button
              className="btn-neutral mr-1"
              color="red"
              href="#"
              id="mimmToggler"
              onClick={()=>setMimmModal(true)}
              size="lg"
            >
              <i className="now-ui-icons files_single-copy-04"></i> Read More..
            </Button>
            <Button
              disabled
              color="info"
              href="https://earlymarkers.youcanbook.me/"
              size="lg"
            >
            <i className="now-ui-icons arrows-1_share-66"></i> Book Now
            </Button>
           
           <Modal 
                isOpen={mimmModal}
                toggle={() => setMimmModal(false)}>
              <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setMimmModal(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="category text-info">Project MIMM</h4>
                </div>
                <div className="modal-body">
                <h5 className="category text-info">Pilot at Kindering</h5>
              Parents can participate by attending a study session, with their infant, at the Kindering Bellevue site.
              The session will be virtual in that the occupational therapist will be on site (not in the study room) and 
              will be directing the play and handling of the infant over Zoom.
              
              <br>
              </br>
              Parents can choose to receive a motor developmental report of their participating infant.

              <br></br>
              If you're participating in MIMM, in preparation for the study session, you can look at
              the following instructions to familiarize yourself with what will happen during the 
              session:
                    <br>
                  </br>
                  <a href = {MIMMInstructions3Months} target = "_blank"> Instructions for 3-Month Olds </a>
                  <br>
                  </br>

                  <a href = {MIMMInstructions6Months} target = "_blank"> Instructions for 6-Month Olds </a>
                  <br>
                  </br>
                  <br>
                  </br> 
              For additional details, see the flyer below
              <br></br>
              <a href = {MIMMFlyerPdf} target = "_blank"> Check out the recruitment flyer here </a>
              <br></br>

              <br></br>
              <h5 className="category text-info">About MIMM</h5>
            
                  This study, Modeling Infant Motor Movements (MIMM), is designed to obtain high quality movement data from infants in a naturalistic
            setting. We needed high-quality infant movement data, obtained from video cameras, to train our AI system to recognize
               infant body joints (pose estimation) and motor activities. As part of this effort, we commissioned a study
              in 2017 to gather infant movement data. Infants of both sexes, in the age group 3-, 6-, 9-, and 12-months, were recruited
              from the greater Seattle area. Thanks to the overwhelming support of parents, we were able to gather data from
              over 60 infants in short assessment sessions.
              <br></br>
              An extended labeling phase yielded a couple of million infant body joint locations, manually and painstakingly labeled.
              This training data was used to produce a state of the art infant pose estimation system that can predict
              the body joint locations of infants from video.
              <br>
              </br>
              We also labeled motor activity sequences from videos, producing over 3000 such samples for training of our 
              Activity Classifier.   
            
              
                </div>
                <ModalFooter>
                 
                  <Button color="primary" onClick={() => setMimmModal(false)}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>

       
          </div>
        </Col>
      </Row>
      </div>
    ),
    altText: "",
    caption: ""
  }
 

];
  
  return (
  
    <>
     <ScrollTransparentNavbarEM/>
      <div className="cd-section wrapper" id="headers">
       
        <div className="header-3">
         
          <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false} touch={true}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
              color="primary"
            />
            {items.map(item => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <div className="page-header header-filter">
                    <div
                      className="page-header-image"
                      style={{
                        backgroundImage: item.src
                      }}
                    ></div>
                    <div className="content">
                      <Container className="text-left">
                        {item.content}
                      </Container>
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="carousel-control-prev-icon info" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="carousel-control-next-icon" color="info" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div>

      </div>
     
      
      <FooterBlackEM/>
    </>
  );
}

export default StudiesEM;