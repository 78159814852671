import React from "react";
import { Link } from "react-router-dom";
import emLogo from "assets/img/emlogo_new_bright.png";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip
} from "reactstrap";

function ScrollTransparentNavbarEM() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 50 || document.body.scrollTop) > 50
      ? ""
      : " navbar-transparent"
  );

  const [navLinkColor, setNavLinkColor] = React.useState(
    (document.documentElement.scrollTop > 50 || document.body.scrollTop) > 50
      ? "text-em-bold-primary"
      : "text-em-bold-white"
  );

  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > 50 || document.body.scrollTop) > 50
      ? "nav-link btn-primary"
      : "nav-link btn-info"
  );
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setNavbarColor("");
        setBuyButtonColor("nav-link btn-primary");
        setNavLinkColor("text-em-bold-primary")
      } else if (
        document.documentElement.scrollTop < 50 ||
        document.body.scrollTop < 50
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("nav-link btn-info");
        setNavLinkColor("text-em-bold-white")
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="em-background" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img
              width="200"
              height="35"
              alt="..."
              className="img"
              src={require("assets/img/emlogo_new_bright.png")}
            ></img>
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
              Early Markers Home
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
             
             <NavItem>
               <NavLink href="/news" className={navLinkColor} >

               <i className="now-ui-icons objects_globe"></i>
                 News
               </NavLink>
             </NavItem>
             

              <NavItem>
               <NavLink href="/projects" className={navLinkColor}>

               <i className="now-ui-icons files_paper"></i>
                 Projects
               </NavLink>
             </NavItem>

             <NavItem>
               <NavLink href="/landing-page-em#about-em" className={navLinkColor}>

               <i className="now-ui-icons users_single-02"></i>
                 About
               </NavLink>
             </NavItem>

             <NavItem>
               <NavLink href="/landing-page-em#contact-em" className={navLinkColor}>

               <i className="now-ui-icons ui-1_send"></i>
                 Contact
               </NavLink>
             </NavItem>

              <NavItem>
                <Button
                  // className="nav-link btn-default"
                  // color={buyButtonColor}
                  className={buyButtonColor}
                  href="https://app.cribsy.ai"
                  target="_blank"
                  id="signup_button"
                >
                  <p>Launch Cribsy</p>
                  <UncontrolledTooltip target="signup_button">
                  Launch Cribsy
                  </UncontrolledTooltip>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ScrollTransparentNavbarEM;
